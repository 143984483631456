.pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.paginationLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--spacing-32);
  height: var(--spacing-32);
  border-radius: 50%;
}

.paginationLink[aria-current='page'] {
  font-weight: var(--font-weight-bold);
  background-color: var(--color-text-link);
  color: var(--color-text-inverted);
}
