.root {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-card);
  background-color: var(--color-bg-card);
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.th,
.td {
  padding: var(--spacing-16) var(--spacing-24);
  border-block-end: 1px solid var(--color-border);
  text-align: start;
}

.th {
  font-weight: var(--font-weight-bold);
}

.td {
  vertical-align: top;
}
